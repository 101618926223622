import { useThrottleFn } from "@vueuse/core";

import type { GameLastWinner } from "@/types";

type EventData<TData> = {
	type: string;
	action: string;
	data: TData;
};

const MAX_SAVED_DATA_SIZE = 15;

const useRecentGamesWinners = ({
	enableThrottling = false,
	throttleTimeout = 2000
}: { enableThrottling?: boolean; throttleTimeout?: number } = {}) => {
	const { activeGameMode } = useSwitchMode();
	const { data: appInit } = useAppInitData();
	const { add, remove } = useSockets();
	const state = useState<Map<number, GameLastWinner>>("recentWinners", () => new Map());
	const item = ref<GameLastWinner>();
	const handlePlayerLastAction = ({ data }: EventData<GameLastWinner>) => {
		if (state.value.size > MAX_SAVED_DATA_SIZE) {
			state.value.clear();
		}

		if (appInit.value?.isGuest && data.gameMode === "TournamentPoints") {
			state.value.set(data.data.id, data);
			item.value = data;
			return;
		}

		if (activeGameMode.value === "entries") {
			state.value.set(data.data.id, data);
			item.value = data;
		} else if (activeGameMode.value === "coins" && data.gameMode === "TournamentPoints") {
			state.value.set(data.data.id, data);
			item.value = data;
		}
	};

	const throttledPlayerLastAction = useThrottleFn(handlePlayerLastAction, throttleTimeout);

	add("addPlayerLastAction", enableThrottling ? throttledPlayerLastAction : handlePlayerLastAction);

	onUnmounted(() => {
		remove("addPlayerLastAction");
	});

	return { state, item };
};

export default useRecentGamesWinners;
